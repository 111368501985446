import React, { useCallback } from "react"

import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Button } from "decentraland-ui/dist/components/Button/Button"

import { HelpTab } from "../../pages/help"
import { Faq } from "../Icon/Faq"
import { Support } from "../Icon/Support"
import StatusDropdown from "./StatusDropdown"

type HelpCenterSectionProps = {
  setTab: (tab: HelpTab) => void
  activeTab: HelpTab
  serviceList: { name: string; url: string }[]
}

export default function HelpCenterSection(props: HelpCenterSectionProps) {
  const l = useFormatMessage()

  const handleButtonClick = useCallback(
    (tabChange: HelpTab) => () => {
      props.setTab(tabChange)
    },
    [props.setTab]
  )

  return (
    <div className="help-center">
      <div className="help-center__texts">
        <div className="help-center--mobile-header">
          <h1>{l("component.landing.help.center.title")}</h1>
          <StatusDropdown
            serviceList={props.serviceList}
            className="help-center-status-dropdown--mobile"
          />
        </div>
        <p>{l("component.landing.help.center.paragraph")}</p>
      </div>
      <div className="help-center__buttons">
        <div
          className={TokenList.join([
            "help-center__button--faq",
            props.activeTab === HelpTab.FAQ && "help-center__button--active",
          ])}
          onClick={handleButtonClick(HelpTab.FAQ)}
        >
          <Button text="primary" basic>
            <Faq dark={props.activeTab === HelpTab.FAQ} />{" "}
            {l("component.landing.help.center.faq_button_label")}
          </Button>
        </div>
        <div
          className={TokenList.join([
            "help-center__button--support-updates",
            props.activeTab === HelpTab.SUPPORT_UPDATES &&
              "help-center__button--active",
          ])}
          onClick={handleButtonClick(HelpTab.SUPPORT_UPDATES)}
        >
          <Button text="primary" basic>
            <Support dark={props.activeTab === HelpTab.SUPPORT_UPDATES} />{" "}
            {l("component.landing.help.center.support_updates_button_label")}
          </Button>
        </div>
      </div>
    </div>
  )
}
