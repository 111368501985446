import React from "react"

type CircleAndArrowProps = React.SVGAttributes<SVGElement> & {
  isOpen: boolean
}

export const CircleAndArrow = React.memo(function (props: CircleAndArrowProps) {
  return (
    <svg
      className={props.isOpen ? "open" : "close"}
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity={props.isOpen ? "1" : "0.2"}
        cx="36"
        cy="36"
        r="35"
        stroke="white"
        fill={props.isOpen ? "white" : "none"}
        strokeWidth="2"
      />
      <g clipPath="url(#clip0_529_3930)">
        <path
          d="M45 33.0022L42.885 30.8872L36 37.7572L29.115 30.8872L27 33.0022L36 42.0022L45 33.0022Z"
          fill={props.isOpen ? "#242129" : "white"}
        />
      </g>
      <defs>
        <clipPath id="clip0_529_3930">
          <rect
            width="36"
            height="36"
            fill="white"
            transform="translate(54 18) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  )
})
