import React from "react"

import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"

import useLandingContentfulQuery from "../../hooks/useLandingContentfulQuery"
import FaqList from "../Landing/LandingFaqs/Faqs"

type FAQSectionProps = {
  isActive: boolean
}

export default function FAQSection(props: FAQSectionProps) {
  const landingContentful = useLandingContentfulQuery()
  const l = useFormatMessage()

  return props.isActive ? (
    <div className="help-section">
      <div className="help-section__texts">
        <h3>{l("component.landing.help.faq_section.title")}</h3>
        <p>
          {l("component.landing.help.faq_section.paragraph")}&nbsp;
          <a href="https://docs.decentraland.org/player/general/faq/">
            {l("component.landing.help.faq_section.cta_link")}.
          </a>
        </p>
      </div>
      <div className="help-section__accordion">
        <FaqList
          className="help-section__accordion--round-sizes"
          faqs={landingContentful.faq}
        />
      </div>
    </div>
  ) : null
}
