import React from "react"

import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"

import useLandingContentfulQuery from "../../hooks/useLandingContentfulQuery"
import FaqList from "../Landing/LandingFaqs/Faqs"

type SupportUpdatesSectionProps = {
  isActive: boolean
}

export default function SupportUpdatesSection(
  props: SupportUpdatesSectionProps
) {
  const landingContentful = useLandingContentfulQuery()
  const l = useFormatMessage()

  return props.isActive ? (
    <div className="help-section">
      <div className="help-section__texts">
        <h3>{l("component.landing.help.support_updates_section.title")}</h3>
      </div>
      <div className="help-section__accordion">
        <FaqList
          faqs={landingContentful.supportFaq}
          className="help-section__accordion--round-sizes"
        />
      </div>
    </div>
  ) : null
}
