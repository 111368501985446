// StatusIcon.tsx
import React from "react"

import { StatusHealthy } from "../Icon/StatusHealthy"
import { StatusUnhealthy } from "../Icon/StatusUnhealthy"
import { StatusWarning } from "../Icon/StatusWarning"
import { ServiceStatus } from "./utils"

type StatusResultProps = {
  status: ServiceStatus
}

const StatusResult = ({ status }: StatusResultProps) => {
  switch (status) {
    case ServiceStatus.Ok:
      return (
        <StatusHealthy className="status-dropdown__icon status-dropdown__icon--green" />
      )
    case ServiceStatus.Down:
      return (
        <StatusUnhealthy className="status-dropdown__icon status-dropdown__icon--red" />
      )
    case ServiceStatus.Slow:
      return (
        <StatusWarning className="status-dropdown__icon status-dropdown__icon--orange" />
      )
    default:
      return <span className="status-dropdown__dropdown-icon">❔</span>
  }
}

export default StatusResult
