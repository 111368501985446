import React, { useState } from "react"

import useAsyncEffect from "decentraland-gatsby/dist/hooks/useAsyncEffect"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Dropdown } from "decentraland-ui/dist/components/Dropdown/Dropdown"
import { Loader } from "decentraland-ui/dist/components/Loader/Loader"

import StatusResult from "./StatusResult"
import {
  ServiceStatus,
  determineGlobalStatus,
  fetchServiceStatus,
} from "./utils"

type Service = { name: string; url: string }

type StatusDropdownProps = {
  serviceList: Service[]
  className?: string
}

const StatusDropdown = ({ serviceList, className }: StatusDropdownProps) => {
  const [statuses, setStatuses] = useState<{ [key: string]: ServiceStatus }>({})
  const [loading, setLoading] = useState(true)
  const [globalStatus, setGlobalStatus] = useState({
    status: ServiceStatus.Unknown,
    color: "",
    text: "",
  })
  const l = useFormatMessage()

  useAsyncEffect(async () => {
    const statusPromises = serviceList.map((service) =>
      fetchServiceStatus(service.url).then((result) => ({
        name: service.name,
        status: result.status,
      }))
    )

    const resolvedStatuses = await Promise.all(statusPromises)
    const statuses = resolvedStatuses.reduce((acc, { name, status }) => {
      acc[name] = status
      return acc
    }, {} as { [key: string]: ServiceStatus })

    setStatuses(statuses)
    setGlobalStatus(determineGlobalStatus(statuses, l))
    setLoading(false)
  }, [serviceList])

  if (loading) {
    return (
      <div className={TokenList.join(["status-dropdown", className])}>
        <Loader active inline />
      </div>
    )
  }

  return (
    <div className={TokenList.join(["status-dropdown", className])}>
      <div
        className="status-dropdown__icon-wrapper"
        data-tip={globalStatus.text}
      >
        <StatusResult status={globalStatus.status} /> {name}
      </div>
      <Dropdown
        text={l("component.landing.help.dropdown.title")}
        className={TokenList.join([
          "status-dropdown__dropdown",
          `status-dropdown__dropdown--${globalStatus.color}`,
        ])}
      >
        <Dropdown.Menu className="status-dropdown__menu">
          <div
            className={TokenList.join([
              "status-dropdown__header",
              `status-dropdown__header--${globalStatus.color}`,
            ])}
          >
            {globalStatus.text}
          </div>
          {Object.entries(statuses).map(([name, status]) => (
            <Dropdown.Item key={name} className="status-dropdown__item">
              <StatusResult status={status} /> {name}
            </Dropdown.Item>
          ))}
          <div className="status-dropdown__view-details">
            <a href="https://status.decentraland.org/">
              {l("component.landing.help.dropdown.cta")}
            </a>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default React.memo(StatusDropdown)
