import React from "react"

export const StatusUnhealthy = React.memo(function (props: {
  className?: string
}) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <circle cx="8" cy="8.5" r="8" fill="#FF2D55" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.40745 5.995C7.40745 5.66789 7.67293 5.40242 8.00003 5.40242C8.32714 5.40242 8.59261 5.66789 8.59261 5.995V8.36531C8.59261 8.69242 8.32714 8.95789 8.00003 8.95789C7.67293 8.95789 7.40745 8.69242 7.40745 8.36531V5.995ZM7.40746 10.1431C7.40746 9.81595 7.67294 9.55047 8.00004 9.55047C8.32715 9.55047 8.59262 9.81595 8.59262 10.1431C8.59262 10.4702 8.32715 10.7356 8.00004 10.7356C7.67294 10.7356 7.40746 10.4702 7.40746 10.1431ZM13.2531 11.5374C13.1174 11.7774 12.8531 11.9208 12.5461 11.9208H3.45358C3.14662 11.9208 2.88233 11.7774 2.74722 11.5374C2.68323 11.4248 2.59908 11.1955 2.7573 10.9347L7.30297 3.39831C7.57971 2.93966 8.41999 2.93966 8.69672 3.39831L13.243 10.9347C13.4006 11.1955 13.3171 11.4248 13.2531 11.5374Z"
        fill="white"
      />
    </svg>
  )
})
