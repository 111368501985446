import React from "react"

import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"

import "./Faq.css"

type FaqProps = React.SVGAttributes<SVGElement> & {
  dark?: boolean
}

export const Faq = React.memo(function (props: FaqProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      fillRule="evenodd"
      xmlns="http://www.w3.org/2000/svg"
      className={TokenList.join([
        "icon-faq",
        props.className,
        props.dark && "dark-mode",
      ])}
    >
      <path
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 17H9V15H11V17ZM13.07 9.25L12.17 10.17C11.45 10.9 11 11.5 11 13H9V12.5C9 11.4 9.45 10.4 10.17 9.67L11.41 8.41C11.78 8.05 12 7.55 12 7C12 5.9 11.1 5 10 5C8.9 5 8 5.9 8 7H6C6 4.79 7.79 3 10 3C12.21 3 14 4.79 14 7C14 7.88 13.64 8.68 13.07 9.25Z"
        fill="#FCFCFC"
      />
    </svg>
  )
})
