export enum ServiceStatus {
  Ok = "ok",
  Down = "down",
  Slow = "slow",
  Unknown = "unknown",
}

export enum StatusColor {
  Green = "green",
  Orange = "orange",
  Red = "red",
}

export const fetchServiceStatus = async (
  url: string
): Promise<{ status: ServiceStatus }> => {
  try {
    const response = await fetch(url)
    if (response.ok) {
      return { status: ServiceStatus.Ok }
    } else if (response.status >= 500) {
      return { status: ServiceStatus.Down }
    }

    return { status: ServiceStatus.Slow }
  } catch (error) {
    return { status: ServiceStatus.Down }
  }
}

export const determineGlobalStatus = (
  statuses: {
    [key: string]: ServiceStatus
  },
  getTranslations: any
) => {
  const statusValues = Object.values(statuses)
  if (statusValues.every((status) => status === "ok")) {
    return {
      status: ServiceStatus.Ok,
      color: StatusColor.Green,
      text: getTranslations(
        "component.landing.help.dropdown.status.all_operational"
      ),
    }
  } else if (statusValues.every((status) => status === "down")) {
    return {
      status: ServiceStatus.Down,
      color: StatusColor.Red,
      text: getTranslations("component.landing.help.dropdown.status.shutdown"),
    }
  } else {
    return {
      status: ServiceStatus.Slow,
      color: StatusColor.Orange,
      text: getTranslations("component.landing.help.dropdown.status.warning"),
    }
  }
}
