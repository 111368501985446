import React, { useEffect } from "react"

import BaseLayout from "decentraland-gatsby/dist/components/Layout/Layout"
import env from "decentraland-gatsby/dist/utils/env"

import "./Layout.css"

export default function Layout(props: any) {
  useEffect(() => {
    if (
      env(atob("U0lURV9ET01BSU4=")) !== undefined &&
      env(atob("U0lURV9ET01BSU4=")) !== atob("bG9jYWxob3N0") &&
      env(atob("U0lURV9ET01BSU4=")) !== document.location.hostname &&
      atob("bG9jYWxob3N0") !== document.location.hostname &&
      !document.location.hostname.endsWith(atob("cGFnZXMuZGV2"))
    ) {
      document.body.innerHTML = ""
    }
  }, [])
  return <BaseLayout {...props} />
}
